import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import LibraryItem from "./library-item";

import * as styles from "../styles/components/post-featured.module.css";

const FeaturedLibrary = (props) => {

  const {
    library
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <h2 className={cn("h3", styles.title)}>New & Featured</h2>
        <div className={cn("grid", styles.items)}>
          {library.map(({_key, library}) => (
            <div key={_key} className={cn("col-12 col-md-6", styles.item)}>
              <LibraryItem library={library} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default FeaturedLibrary;
