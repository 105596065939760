import React from "react";
import { Link, navigate } from "gatsby";
import { getLibraryTypeUrl } from "../lib/helpers";
import { cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/post-term-list.module.css";

const LibraryTypeList = (props) => {

  const {
    items
  } = props;

  function handleToggleFilter(e) {
    const filter = document.getElementById('filter');

    if(filter.classList.contains('active')) {
      filter.classList.remove('active');
      document.body.classList.remove('overlay-active');

      setTimeout(()=>{
        filter.classList.remove('on-top');
      }, 250);
    }
    else {
      filter.classList.add('active', 'on-top');
      document.body.classList.add('overlay-active');
    }
  }

  function navigateTo(e) {
    e.preventDefault();
    navigate(e.target.value);
  }

  return (
    <div className={styles.root} id="filter">
      <div className={cn(styles.list, "m-hide")}>
        <button className={styles.filterToggle} onClick={handleToggleFilter}>Filter Article<Icon symbol="chevron-down" /></button>
        <ul>
          <li>
            <Link to="/library">All Articles</Link>
          </li>
          {items.map(({node: item}) => (
            <li key={item.id}>
              <Link to={getLibraryTypeUrl(item.slug)}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>

      <div className={cn(styles.dropdown, "d-hide")}>
        <span>Filter Article</span>
        <Icon symbol="chevron-down" />
        <select onChange={navigateTo}>
          <option value="/library">All Articles</option>
          {items.map(({node: item}) => (
            <option key={item.id} value={getLibraryTypeUrl(item.slug)}>{item.title}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default LibraryTypeList;
