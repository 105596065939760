import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import LibraryItem from "./library-item";
import LibraryTypeList from "./library-type-list";
import Pagination from "./pagination";

import * as styles from "../styles/components/post-list.module.css";

const LibraryList = (props) => {

  const {
    library,
    types,
    landingTitle,
    currentType,
    currentPage,
    numPages
  } = props;

  return (
    <div className={styles.root} id="post-list">
      <Container>
        <div className={cn("grid justify-md-between align-center", styles.titleRow)}>
          <div className="col-12 col-md-auto">
            {currentType ? (
              <>
                <Link className={styles.back} to="/library"><Icon symbol="arrow-left" />{landingTitle}</Link>
                <h1 className={cn("h3", styles.title)}>{currentType.title}</h1>
              </>
            ) : (
              <h2 className={cn("h3", styles.title)}>All Articles</h2>
            )}
          </div>

          {types && (<div className="col-12 col-md-auto"><LibraryTypeList items={types} /></div>)}
        </div>

        <div className="grid">
          {library.map(({node: library}) => (
            <div key={library.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
              <LibraryItem library={library} />
            </div>
          ))}
        </div>

        {numPages > 1 && (<Pagination base={currentType ? "/library/"+currentType.slug.current : "/library"} currentPage={currentPage} numPages={numPages} />)}
      </Container>
    </div>
  );
}

export default LibraryList;
