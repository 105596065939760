import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/post-landing-page-header";
import FeaturedLibrary from "../components/library-featured";
import LibraryList from "../components/library-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query LibraryLandingQuery($skip: Int!, $limit: Int!) {
    libraryLanding: sanityLibraryLanding {
      title
      themePurple
      _rawDescription
      featuredLibrary {
        _key
        library {
          title
          slug {
            current
          }
          thumbnail {
            ...SanityImage
            alt
          }
          types {
            _key
            libraryType {
              title
              slug {
                current
              }
            }
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    library: allSanityLibrary(
      sort: { fields: [orderRank], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          thumbnail {
            ...SanityImage
            alt
          }
          types {
            _key
            libraryType {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }

    libraryAll: allSanityLibrary(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          types {
            libraryType {
              id
            }
          }
        }
      }
    }

    libraryTypes: allSanityLibraryType(
      sort: { fields: [orderRank], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const LibraryLandingTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const libraryLanding = (data || {}).libraryLanding;

  const library = (data || {}).library.edges;
  const libraryAll = (data || {}).libraryAll.edges;
  
  const libraryTypes = (data || {}).libraryTypes.edges.filter(type => {
    const libraryInType = libraryAll.filter(post => (
      post.node.types.map(({libraryType}) => libraryType.id).includes(type.node.id)
    ));
    return libraryInType.length > 0;
  });

  const title = libraryLanding.seo?.title || libraryLanding.title;
  const description = libraryLanding.seo?.description || toPlainText(libraryLanding._rawDescription);
  const image = libraryLanding.seo?.thumbnail;
  const autoSiteTitle = !libraryLanding.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={libraryLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {libraryLanding.title && (<PageHeader title={libraryLanding.title} description={libraryLanding.description} />)}
      {libraryLanding.featuredLibrary && libraryLanding.featuredLibrary.length > 0 && (<FeaturedLibrary library={libraryLanding.featuredLibrary} />)}
      {library && (<LibraryList library={library} types={libraryTypes} currentPage={currentPage} numPages={numPages} />)}
    </Layout>
  );
};

export default LibraryLandingTemplate;
