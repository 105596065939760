import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj, getLibraryUrl, getLibraryTypeUrl } from "../lib/helpers";
import { Link } from "gatsby";

import * as styles from "../styles/components/post-item.module.css";

const LibraryItem = (props) => {

  const {
    library
  } = props;

  return (
    <div className={styles.root}>
      {library.thumbnail && library.thumbnail.asset && (
        <div className={styles.image}>
          <Link className={styles.link} to={getLibraryUrl(library.slug)}>
            <img
              src={imageUrlFor(buildImageObj(library.thumbnail))
                .auto("format")
                .url()}
              alt={library.thumbnail.alt}
            />
          </Link>
        </div>
      )}
      <div className={styles.text}>
        {library.types && library.types.length > 0 && (
          <div className={styles.meta}>
            {library.types.slice(0, 1).map(({libraryType, _key}) => (
              <Link key={_key} to={getLibraryTypeUrl(libraryType.slug)}>{libraryType.title}</Link>
            ))}
          </div>
        )}

        {library.title && (
          <h3 className={styles.title}><Link className={styles.link} to={getLibraryUrl(library.slug)}>{library.title}</Link></h3>
        )}
      </div>
    </div>
  );
}

export default LibraryItem;
